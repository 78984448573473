import { FuseConfig } from '@fuse/types';

export const fuseConfig: FuseConfig = {
    colorTheme: 'theme-default',
	customScrollbars: true,
	banner: '',
	bgPage: '',
	bgMain: '',
	directorLogo: '',
	hqLogo: '',
	smallLogo: '',
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'custom-primary-500',
            secondaryBackground: 'grey-100',
			customPrimaryBackground: '',
			customPrimaryForeground: '',
			customSecondaryBackground: '',
			customSecondaryForeground: '',
            folded: false,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-2'
        },
		toolbar: {
			toolBarLogo: '',
            customBackgroundColor: true,
            background: 'fuse-white-500',
			customToolbarBackground: '',
          	customToolbarForeground: '',
            hidden: false,
            position: 'below-static'
        },
        footer: {
            customBackgroundColor: true,
            background: 'custom-warn-500',
			customFooterBackground: '',
			customFooterForeground: '',
            hidden: false,
            position: 'below-static',
			email: 'dave@usasoftballdfw.org',
			facebook: 'https://www.facebook.com/dfwusasoftball',
			instagram: 'https://www.instagram.com/usasoftball/',
			twitter: 'https://twitter.com/usasoftball',
			youtube: 'https://www.youtube.com/softballoutlet',
			linkedin: '',
			forum: ''
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        },
        pageheader: {
            primaryBackground: 'custom-warn-500',
			customHeaderPrimaryBackground: '',
			customHeaderPrimaryForeground: ''
        },
        linkActiveColor: {
            primaryBackground: 'custom-warn-500',
            customLinkBackground: '',
            customLinkForeground: ''
        }
    }
};
